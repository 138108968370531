import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'accept-cookies',
  templateUrl: './accept-cookies.component.html',
  styleUrls: ['./accept-cookies.component.scss']
})
export class AcceptCookiesComponent implements OnInit {
  @Output() isCookiesAcceptedBeforeLogin: EventEmitter<boolean> = new EventEmitter();
  public latitude: any;
  public longitude: any;
  public isLoggedIn: boolean = false;
  constructor(private apiService: ApiService, private authService: AuthService) {
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  ngOnInit(): void {
    //this.getLocation();
  }

  public acceptCookies() {
    var browserInfo = this.getBrowserName();
    this.getLocation();
    var data = {
      browserInfo: browserInfo,
      latutude: this.latitude,
      longitude: this.longitude,
    }
    this.apiService.post('user/cookies/add', data).subscribe((response: any) => {
      if (response) {
        window.location.reload();
      }
    })
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  public getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        },
        (error) => {
          console.error('Error getting location', error);
        }
      );
    }
  }

  public acceptCookiesWithoutLoggedIn() {
    this.isCookiesAcceptedBeforeLogin.emit(false);
  }

  public openPrivacyDialog() {
    window.open("https://www.thrivepass.com/privacy");
  }
}
