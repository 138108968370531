import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../models/api.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private configData: Configuration = new Configuration();

  constructor(private httpClient: HttpClient) { }

  public loadConfig(): Promise<Configuration> {
    const promise = this.httpClient.get('/config')
      .toPromise()
      .then((response: any) => {
        this.configData = response.value;
        return this.configData;
      });
    return promise;
  }

  get apiBaseUrl(): any {
    return this.configData.apiBaseUrl;
  }

  get clientBaseUrl(): any {
    return this.configData.clientBaseUrl;
  }

  get msalConfig(): any {
    return this.configData.msalConfig;
  }

  get applicationInsights(): any {
    return this.configData.applicationInsights;
  }
}
