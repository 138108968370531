import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigurationService } from './configuration.service';
import { filter } from 'rxjs/operators';
import { ApplicationInsights, IExceptionTelemetry } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root'
})

export class AppInsightsService {

  private appInsights! : ApplicationInsights;

  constructor(private router: Router, private configService: ConfigurationService) {

    try {

      this.appInsights = new ApplicationInsights({

        config: {
          connectionString: this.configService.applicationInsights.connectionString,
          enableAutoRouteTracking: true, // option to log all route changes
          enableCorsCorrelation: true
        }
      });

      this.appInsights.loadAppInsights();
      this.loadCustomTelemetryProperties();
      this.createRouterSubscription();
    } catch (ex) { }

  }

  setUserId(userId: string) {

    try {
      this.appInsights.setAuthenticatedUserContext(userId);
    } catch (ex) { }

  }

  clearUserId() {

    try {

      this.appInsights.clearAuthenticatedUserContext();

    } catch (ex) { }

  }

  logPageView(name?: any, uri?: string) {

    try {

      this.appInsights.trackPageView({ name, uri });

    } catch (ex) { }

  }

  logException(error: Error) {

    try {

      let exception: IExceptionTelemetry = {

        exception: error

      };

      this.appInsights.trackException(exception);

    } catch (ex) { }

  }

  private loadCustomTelemetryProperties() {

    try {

      this.appInsights.addTelemetryInitializer((envelope:any) => {

        var item: any = envelope.baseData;

        item.properties = item.properties || {};

        item.properties["ApplicationPlatform"] = "WEB";

        item.properties["ApplicationName"] = "IRT";

      });

    } catch (ex) { }

  }

  private createRouterSubscription() {

    try {

      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {

        this.logPageView(null, event.urlAfterRedirects);

      });

    } catch (ex) { }
  }
}
